// eslint-disable-next-line camelcase
export const camelCaseTo_under_score = str =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const addCurrencySign = (amount, currency) => {
  if (amount !== null && amount !== undefined) {
    return (amount = currency + amount);
  }
  return amount;
};
