import ajaxUtils from '../utils/ajaxUtils';
import { get } from '../utils/axios';

// eslint-disable-next-line import/prefer-default-export
export const getWithdrawalReadyBalance = async () => {
  const response = await get(`${ajaxUtils.dashboard}/withdrawal-ready-balance`);
  return response?.data;
};

// eslint-disable-next-line import/prefer-default-export
export const getDashboardTopCampaigns = async timeRange => {
  const response = await get(`${ajaxUtils.dashboard}/topCampaigns`, {
    startDate: timeRange?.[0].toISOString(),
    endDate: timeRange?.[1].toISOString()
  });
  return response?.data;
};
export const getDashboardOverview = async timeRange => {
  const response = await get(`${ajaxUtils.dashboard}/overview`, {
    startDate: timeRange?.[0].toISOString(),
    endDate: timeRange?.[1].toISOString()
  });
  return response?.data;
};
export const getDashboardLatestConversions = async () => {
  const response = await get(`${ajaxUtils.dashboard}/latestConversions`);
  return response?.data;
};
export const getDashboardChart = async timeRange => {
  const response = await get(`${ajaxUtils.dashboard}/chart`, {
    startDate: timeRange?.[0].toISOString(),
    endDate: timeRange?.[1].toISOString()
  });
  return response?.data;
};
