import { Box, Button, FormHelperText } from '@mui/material';
import clsx from 'clsx';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { domainSelector } from 'src/selectors/selectors';
import * as Yup from 'yup';
import { getCompanyInitialData } from '../../../actions/configActions';
import { login } from '../../../actions/userActions';
import { THEMES } from '../../../constants';
import useSettings from '../../../hooks/useSettings';
import baseUseStyles from '../../../utils/commonStyles';
import inputsLengthsEnum from '../../../utils/enums/inputsLengthsEnum';
import isValidAddress from '../../../utils/validationUtils';
import { getDashboardOverview } from '../../../actions/dashboardActions';
import { COMPANY_PAYMENT_METHODS } from '../../../actions/companyActions';
import { dateShortcutsItems } from '../../../components/DateRangepicker';

const initialValues = {
  email: '',
  password: ''
};

function LoginForm({ className, onErrorHandler, intl, ...rest }) {
  const { settings, saveSettings } = useSettings();
  const domain = useSelector(domainSelector);

  const classes = baseUseStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { minPasswordLength, maxPasswordLength } = inputsLengthsEnum;

  const validator = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'enter_valid_email' }))
      .max(255)
      .required(intl.formatMessage({ id: 'email_required' }))
      .test(
        'emailRegex',
        intl.formatMessage({ id: 'invalid_email_message' }),
        email => isValidAddress(email)
      ),
    password: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: 'password_required' }))
  });

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      const loggedUser = await dispatch(login(values.email, values.password));
      if (loggedUser) {
        if (loggedUser?.personalization) {
          const newSettings = {
            direction: loggedUser.personalization?.langDirection || 'ltr',
            responsiveFontSizes: true,
            theme: loggedUser.personalization?.theme || THEMES.LIGHT.name,
            lang: loggedUser.personalization?.lang || 'en'
          };

          await saveSettings(newSettings);
          // if (settings.lang !== newSettings.lang) {
          //   await window.location.reload();
          // }
        }
        await dispatch(getCompanyInitialData());
        getDashboardOverview(dateShortcutsItems?.[0].getValue()).then(
          newData => {
            if (newData) {
              dispatch({
                type: COMPANY_PAYMENT_METHODS,
                payload: newData?.paymentMethods
              });
            }
          }
        );
        history.push('/app');
      }
    } catch (error) {
      const message = error.response && error.response.data.message;
      onErrorHandler(message);
      setStatus({ success: false });
      // setErrors({ submit: message });
      setSubmitting(false);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validator}
      onSubmit={onSubmit}
    >
      {({ errors }) => (
        <Form className={clsx(classes.root, className)} {...rest}>
          <Field
            component={TextField}
            name="email"
            type="email"
            label={intl.formatMessage({ id: 'email_address' })}
            fullWidth
            autoFocus
            margin="normal"
            variant="outlined"
          />
          <Field
            component={TextField}
            fullWidth
            label={intl.formatMessage({ id: 'password' })}
            margin="normal"
            name="password"
            type="password"
            variant="outlined"
          />
          <Box mt={2}>
            <Button
              color="secondary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              <FormattedMessage id="log_in" />
            </Button>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
}

LoginForm.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.any,
  onErrorHandler: PropTypes.func
};

export default injectIntl(LoginForm);
