/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  COMPANY_ADVERTISERS,
  COMPANY_BRANDING,
  COMPANY_CURRENCY,
  COMPANY_INITIAL_DATA,
  COMPANY_MERCHANT,
  COMPANY_PAYMENT_METHODS,
  COMPANY_TRAFFIC_SOURCE
} from 'src/actions/companyActions';
import { LOGOUT } from 'src/actions/userActions';
import { SiteTypeEnum } from 'src/components/guards/BrokerGuard';

const initialState = {
  trafficSources: [],
  advertisers: [],
  merchant: {},
  currency: null,
  branding: null,
  minimumWithdrawalAmount: 0,
  mlmActive: false,
  countryCodeRequired: false,
  affiliateNeedsManualActivation: false,
  mlmPercent: null,
  mlmLevel2Active: false,
  mlmLevel2Percent: null,
  mlmLevel3Active: false,
  mlmLevel3Percent: null,
  mlmLevel4Active: false,
  mlmLevel4Percent: null,
  mlmLevel5Active: false,
  mlmLevel5Percent: null,
  defaultCampaign: null,
  singleCampaignMode: false,
  hideAmount: false,
  displayConversionPersonalInfo: false,
  brokerMode: false,
  requireAffiliateLegalStatus: false,
  requiredCompanyName: true,
  displayAccessibilityToolbox: true,
  hideOrderId: false,
  hideFooter: false,
  customFooter: null,
  paymentMethods: [],
  socialMode: false,
  siteType: SiteTypeEnum.GENERAL
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_TRAFFIC_SOURCE: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.trafficSources = action.payload;
      });
    }
    case COMPANY_PAYMENT_METHODS: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.paymentMethods = action.payload;
      });
    }
    case COMPANY_ADVERTISERS: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.advertisers = action.payload;
      });
    }
    case COMPANY_MERCHANT: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.merchant = action.payload;
      });
    }
    case COMPANY_CURRENCY: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.currency = action.payload;
      });
    }
    case COMPANY_BRANDING: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.branding = action.payload;
      });
    }
    case COMPANY_INITIAL_DATA: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.currency = action.payload.currency;
        draft.trafficSources = action.payload.companyTrafficSources;
        draft.advertisers = action.payload.companyAdvertisers;
        draft.minimumWithdrawalAmount = action.payload.minimumWithdrawalAmount;
        draft.mlmActive = action.payload.mlmActive;
        draft.mlmPercent = action.payload.mlmPercent;
        draft.mlmLevel2Active = action.payload.mlmLevel2Active;
        draft.mlmLevel2Percent = action.payload.mlmLevel2Percent;
        draft.mlmLevel3Active = action.payload.mlmLevel3Active;
        draft.mlmLevel3Percent = action.payload.mlmLevel3Percent;
        draft.mlmLevel4Active = action.payload.mlmLevel4Active;
        draft.mlmLevel4Percent = action.payload.mlmLevel4Percent;
        draft.mlmLevel5Active = action.payload.mlmLevel5Active;
        draft.mlmLevel5Percent = action.payload.mlmLevel5Percent;
        draft.defaultCampaign = action.payload.defaultCampaign;
        draft.countryCodeRequired = action.payload.countryCodeRequired;
        draft.affiliatePromotionalMaterial =
          action.payload.affiliatePromotionalMaterial;
        draft.hideFooter = action.payload.hideFooter;
        draft.customFooter = action.payload.customFooter;
        draft.affiliateNeedsManualActivation =
          action.payload.affiliateNeedsManualActivation;
        draft.singleCampaignMode = action.payload.singleCampaignMode;
        draft.displayAccessibilityToolbox =
          action.payload.displayAccessibilityToolbox;
        draft.hideAmount = action.payload.hideAmount;
        draft.displayConversionPersonalInfo =
          action.payload.displayConversionPersonalInfo;
        draft.hideOrderId = action.payload.hideOrderId;
        draft.brokerMode = action.payload.brokerMode;
        draft.siteType = action.payload.siteType;
        draft.requireAffiliateLegalStatus =
          action.payload.requireAffiliateLegalStatus;
        draft.requireCompanyName = action.payload.requireCompanyName;
        draft.displayCustomIdPicker = action.payload.displayCustomIdPicker;
        draft.removeAffiliateWithdrawalRequest =
          action.payload.removeAffiliateWithdrawalRequest;
        draft.supportAffiliatePayoutRequestInvoiceAttachment =
          action.payload.supportAffiliatePayoutRequestInvoiceAttachment;
        draft.supportAffiliatePayoutRequestCustomAmount =
          action.payload.supportAffiliatePayoutRequestCustomAmount;
        draft.affiliatePayoutType = action.payload.affiliatePayoutType;
      });
    }
    case LOGOUT: {
      return { ...initialState, branding: state.branding };
    }

    default: {
      return state;
    }
  }
};

export default companyReducer;
