import {
  ClickAwayListener,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  SvgIcon,
  Typography
} from '@mui/material';
import { Globe } from 'react-feather';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { updatePersonalization } from 'src/actions/userActions';
import FlagIcon from 'src/components/FlagIcon.js';
import { LanguagesEnum, THEMES, getLanguageByKey } from 'src/constants';
import useSettings from 'src/hooks/useSettings';
import { accountSelector } from 'src/selectors/selectors';

export default function LanguagePopover({ showLang = false }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const { translations } = useSelector(state => state?.userInterface);
  const { settings, saveSettings } = useSettings();
  const account = useSelector(accountSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const arrowRef = useRef(null);
  const { search } = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    let lang = queryParams?.get('lang')?.toUpperCase();
    if (lang == 'IL') lang = 'IW'; //to acknowledge old links before changing to iw
    if (lang) {
      queryParams.delete('lang');
      history.replace({
        search: queryParams.toString()
      });
      const language = LanguagesEnum?.[lang];
      changeLanguage(language);
      // .then(() => window.location.reload());
    }
  }, []);

  // ltr = english, rtl = hebrew
  const changeLanguage = async language => {
    const newSettings = {
      direction: language.direction,
      responsiveFontSizes: settings.responsiveFontSizes,
      theme: settings.theme,
      lang: language.lang
    };

    if (account && account?.user) {
      const siteThemeOid = THEMES[newSettings.theme].oid;
      const languageOid = language.value;
      await dispatch(updatePersonalization(siteThemeOid, languageOid));
    }

    await saveSettings(newSettings);
    handleClickAway();
  };

  const openLangPopper = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const currentLang = useMemo(() => getLanguageByKey(settings?.lang), [
    settings?.lang
  ]);

  return (
    <>
      <IconButton
        ref={arrowRef}
        color="inherit"
        onClick={openLangPopper}
        size="large"
      >
        {showLang ? (
          <>
            <FlagIcon countryCode={currentLang?.flag} />
            <Typography sx={{ mx: 1 }}>{currentLang?.label}</Typography>
          </>
        ) : (
          <SvgIcon fontSize="small">
            <Globe />
          </SvgIcon>
        )}
      </IconButton>
      {!!anchorEl && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            open={!!anchorEl}
            anchorEl={anchorEl}
            placement={'bottom'}
            transition
            modifiers={[
              {
                name: 'arrow',
                enabled: true,
                options: {
                  element: arrowRef.current
                }
              }
            ]}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <List>
                    {Object.values(LanguagesEnum)
                      .filter(i => !!translations?.[i?.lang?.toUpperCase?.()])
                      .map(l => (
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={async () => {
                              await changeLanguage(l);
                              // window.location.reload();
                            }}
                          >
                            <ListItemIcon>
                              <FlagIcon countryCode={l.flag} />
                            </ListItemIcon>
                            <ListItemText primary={l.label} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
}
