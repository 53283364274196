import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  Hidden,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import FormattedMessage from 'react-intl/lib/src/components/message';
import Iconify from 'src/components/iconify';
import { accountSelector } from 'src/selectors/selectors';
import { openDocs, openDocsV2, stringToColor } from 'src/utils/utils';
import { logout } from '../../../actions/userActions';
import Label from '../../../components/Label';

function stringAvatar(name) {
  const initials =
    name
      .split(' ')
      .map(word => (word[0] ? String(word[0])?.toUpperCase() : ''))
      .join('') || 'U';

  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: initials,
    alt: initials
  };
}

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
    fontSize: '13px'
  },
  popover: {
    width: 200
  }
}));

function Account() {
  const [isOpen, setOpen] = useState(false);

  const { user } = useSelector(accountSelector);
  const { enqueueSnackbar } = useSnackbar();
  const fullName = useMemo(() => `${user?.firstName} ${user?.lastName}`, [
    user
  ]);
  const imgUrl = useMemo(
    () =>
      user?.profileImage
        ? process.env.REACT_APP_PROFILE_PIC_BASE_URL + user?.profileImage
        : '',
    [user?.profileImage]
  );

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      dispatch(logout(true));
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    Boolean(user) && (
      <>
        <Box
          display="flex"
          alignItems="center"
          component={ButtonBase}
          onClick={handleOpen}
          ref={ref}
        >
          {!!imgUrl ? (
            <Avatar
              {...stringAvatar(fullName)}
              className={classes.avatar}
              src={imgUrl}
            />
          ) : (
            <Avatar {...stringAvatar(fullName)} className={classes.avatar} />
          )}
          <Hidden smDown>
            <Typography variant="h6" color="inherit">
              {`${user?.firstName} ${user?.lastName}`}
            </Typography>
          </Hidden>
        </Box>
        <Menu
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          keepMounted
          PaperProps={{ className: classes.popover }}
          // getContentAnchorEl={null}
          anchorEl={ref.current}
          open={isOpen}
        >
          {/* <MenuItem
          component={RouterLink}
          to="/app/social/profile"
        >
          Profile
        </MenuItem>
        todo need to be  implemented
        */}

          <MenuItem onClick={openDocs}>
            <ListItemIcon>
              <Iconify icon="tabler:api" />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="API" />
            </ListItemText>
            <Typography variant="body2" color="text.secondary">
              <Label color="warning">DEPRECATED</Label>
            </Typography>
          </MenuItem>
          <MenuItem onClick={openDocsV2}>
            <ListItemIcon>
              <Iconify icon="tabler:api" />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="API" /> V2
            </ListItemText>
            <Typography variant="body2" color="text.secondary">
              <Label color="info">New</Label>
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.open('https://help.tracknow.io/', '_blank').focus();
            }}
          >
            <ListItemIcon>
              <Iconify icon="oui:documentation" />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="documentation" />
            </ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Iconify icon="material-symbols:logout" />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="logout" />
            </ListItemText>
          </MenuItem>
        </Menu>
      </>
    )
  );
}

export default Account;
