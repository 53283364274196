import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import useSettings from 'src/hooks/useSettings';
// eslint-disable-next-line camelcase
import casino_en from '../lang/casino_en';
// eslint-disable-next-line camelcase
import { updateTranslations } from 'src/actions/userInterfaceActions';
import LoadingScreen from 'src/components/LoadingScreen';
import { create_UUID } from 'src/utils/utils';
import messages_de from '../lang/de';
import messages_es from '../lang/es';
import messages_in from '../lang/in';
import messages_iw from '../lang/iw';
import messages_ru from '../lang/ru';
import messages_se from '../lang/se';

const defualtMessages = {
  iw: messages_iw,
  es: messages_es,
  se: messages_se,
  de: messages_de,
  in: messages_in,
  ru: messages_ru
};
const casinoMessages = {
  en: casino_en
};
function generateInsertStatements(json) {
  const tableName = 'platform_translation_keys';
  const insertStatements = [];

  for (const [key, value] of Object.entries(json)) {
    const id = create_UUID();
    const lang = '6';
    const siteType = '0';
    const insertStatement = `INSERT INTO \`${tableName}\` (\`id\`, \`lang\`, \`site_type\`, \`value\`, \`translation_key\`) VALUES ('${id}', '${lang}', '${siteType}', '${value}', '${key}');`;
    insertStatements.push(insertStatement);
  }

  return insertStatements;
}

function TranslationProvider({ children }) {
  const { settings } = useSettings();
  const { translations } = useSelector(state => state?.userInterface);

  useEffect(() => {
    updateTranslations();
  }, []);

  return translations ? (
    <IntlProvider
      locale={settings.lang}
      messages={
        translations?.[settings?.lang?.toUpperCase()] ?? translations?.['EN']
      }
    >
      {children}
    </IntlProvider>
  ) : (
    <LoadingScreen />
  );
}
export default TranslationProvider;
