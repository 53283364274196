import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getCompanyDocs } from 'src/actions/docsActions';
import FilesDropzone from 'src/components/FilesDropzone';
import ajaxUtils from 'src/utils/ajaxUtils';
import { post } from 'src/utils/axios';

export default function CustomDocs({ requierdOnly = false }) {
  const [requiredDocs, setRequiredDocs] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () =>
    getCompanyDocs(requierdOnly).then(r => setRequiredDocs(r));

  return (
    <Grid container spacing={3}>
      {requiredDocs?.length ? (
        requiredDocs?.map(r => <DocUpload doc={r} fetchData={fetchData} />)
      ) : (
        <Grid item xs={4} style={{ margin: '0 auto' }}>
          <Card>
            <CardContent>
              <Typography
                variant="h3"
                sx={{ textAlign: 'center', width: '100%' }}
              >
                No documents required
              </Typography>
              <Typography
                variant="h5"
                sx={{ textAlign: 'center', width: '100%', pt: 3 }}
              >
                When the company requests to upload documents, you will be required to upload them here.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}

const DocUpload = ({ doc, fetchData }) => {
  const uploadFiles = async formData => {
    let response = post(
      ajaxUtils.docs.user.upload(doc?.requiredDocsId?.id),
      formData,
      null,
      {
        'Content-Type': 'multipart/form-data'
      }
    ).then(r => fetchData());
  };
  return (
    <Grid item xs={12} md={4}>
      <Card>
        <CardContent>
          <Typography variant="h3">
            {doc?.requiredDocsId?.name}
            {doc?.requiredDocsId?.required && '*'}
          </Typography>
          <Typography variant="h6">
            -{doc?.requiredDocsId?.description}
          </Typography>
          <FilesDropzone
            existingFiles={doc?.uploadedDocs}
            uploadFiles={uploadFiles}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};
