import styled from '@emotion/styled';
import LockIcon from '@mui/icons-material/Lock';
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Link,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import TopBar from 'src/layouts/DashboardLayout/TopBar';
import LanguageButtons from '../../../components/LanguageButtons';
import Page from '../../../components/Page';
import { domainSelector } from '../../../selectors/selectors';
import { loginSx } from '../../../utils/commonStyles';
import LoginForm from './LoginForm';

const PageRoot = styled(Page)(({ theme }) => ({
  justifyContent: 'center',
  backgroundColor: theme.palette.background.dark,
  display: 'flex',
  minHeight: '100VH',
  flexDirection: 'column',
  paddingBottom: 80
}));

const StyledCard = styled(Card)(({ theme }) => ({
  overflow: 'visible',
  display: 'flex',
  position: 'relative',

  '& > *': {
    flexGrow: 1,
    flexBasis: '50%',
    width: '50%'
  }
}));

const CardContentContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(8, 4, 3, 4)
}));

const CardMediaMedia = styled(CardMedia)(({ theme }) => ({
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',

  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}));

// eslint-disable-next-line react/prop-types
function LoginView({ intl }) {
  const domain = useSelector(domainSelector);

  const initialState = {
    error: false,
    errorMessage: ''
  };
  const [errorState, setError] = useState(initialState);
  // eslint-disable-next-line react/prop-types
  const onErrorHandler = (
    errorMessage = intl.formatMessage({ id: 'general_error' })
  ) => {
    setError({
      error: true,
      errorMessage
    });
  };

  return domain?.domainType == 0 ? (
    <LoadingScreen />
  ) : (
    <PageRoot title="Login" maxWidth="sm">
      <TopBar onMobileNavOpen={() => {}} />
      <StyledCard
        sx={{
          marginTop: {
            xs: 10
          }
        }}
      >
        <CardContentContent>
          <Avatar sx={loginSx.icon}>
            <LockIcon fontSize="large" />
          </Avatar>
          <Typography variant="h2" color="textPrimary">
            <FormattedMessage id="sign_in" />
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            <FormattedMessage id="sign_in_on_the_internal_platform" />
          </Typography>
          {/* eslint-disable-next-line consistent-return */}
          {(() => {
            if (errorState.error) {
              return (
                <Box mt={2}>
                  <Alert severity="error" id="loginErrorMessageWarrper">
                    <p id="loginErrorMessage">{errorState.errorMessage}</p>
                  </Alert>
                </Box>
              );
            }
          })()}
          <Box mt={3}>
            <LoginForm onErrorHandler={message => onErrorHandler(message)} />
          </Box>
          <Box my={2}>
            <Divider />
          </Box>
          <LanguageButtons />
          {domain.affiliateDomain ? (
            <Box display="flex" justifyContent="space-between">
              <Link
                component={RouterLink}
                to="/register"
                variant="body2"
                color="textSecondary"
                style={{ float: 'left' }}
              >
                <FormattedMessage id="create_new_account" />
              </Link>
              <Link
                component={RouterLink}
                to="/forgotPassword"
                variant="body2"
                color="textSecondary"
                style={{ float: 'right' }}
              >
                <FormattedMessage id="forgot_password" />
              </Link>
            </Box>
          ) : (
            <></>
          )}
        </CardContentContent>
        {/* <CardMediaMedia image="/static/images/auth.png" title="Cover">
            <Typography color="inherit" variant="subtitle1"></Typography>
            <Box alignItems="center" display="flex" mt={3}>
              <Box ml={3}>
                <Typography color="inherit" variant="body1"></Typography>
                <Typography color="inherit" variant="body2"></Typography>
              </Box>
            </Box>
          </CardMediaMedia> */}
      </StyledCard>
    </PageRoot>
  );
}

export default injectIntl(LoginView);
